.root {
  --color-ced4da: #ced4da,
}

.green {
  color: green;
}

.red {
  color: red;
}

.quill {
  background-color: white;
}

.form-control {
  box-shadow: none;
  color: var(--color-ced4da);
}

.title {
  font-size: 28px;
  font-family: Roboto !important;
}

.sub-title {
  font-size: 24px;
  font-family: Roboto !important;
}

.desc {
  font-size: 14px;
  font-family: Roboto !important;
}

.success {
  color: green;
  font-size: xx-large;
}

.unsuccess {
  color: red;
  font-size: xx-large;
}

.processing {
  color: rgb(242, 255, 0);
  font-size: xx-large;
}

.colors {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.colors li {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 0.5px solid white
}

.colors .bg-black {
  --bs-bg-opacity: 0.15;
  border: 0.5px solid black
}



/* admin image  */

.admin-image {
  background-color: rgb(72, 102, 11);
  font-size: 19px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}

/* admin image  */